@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Internet Explorer and Edge */
.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}